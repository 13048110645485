/* eslint-disable react/prop-types */
import React from "react";

// project imports
import style from "./layout.module.scss";
import containerStyle from "./container.module.scss";
import Navbar from "./navbar";
import Footer from "./footer";

const Layout = (props) => {
  return (
    <div className={style.view}>
      <Navbar />
      <div className={style.content}>
        <div className={containerStyle.container}>{props.children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
