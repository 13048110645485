/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import searchboxStyle from "./searchbox.module.scss";

const SearchBox = (props: any) => {
  return (
    <form onSubmit={() => {}}>
      <div className={searchboxStyle.container}>
        <div className={searchboxStyle.slug}>Find your dream job, today!</div>
        <input
          className={`${searchboxStyle.keyword} ${searchboxStyle.input}`}
          name="keyword"
          placeholder="Job title, skill, company"
          type="text"
          value={props.querySearchTerm}
          onChange={(e) => props.setQuerySearchTerm(e.target.value)}
          autoFocus
        />
        <input
          className={`${searchboxStyle.location} ${searchboxStyle.input}`}
          name="location"
          placeholder="Location"
          type="text"
          value={props.queryLocation}
          onChange={(e) => props.setQueryLocation(e.target.value)}
        />
        <input
          className={searchboxStyle.button}
          type="submit"
          value="Search"
          onClick={(e) => {
            e.preventDefault();
          }}
        />
      </div>
    </form>
  );
};

export default SearchBox;
