import { GraphQLClient } from "graphql-request";
import { print } from "graphql";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Node = {
  id: Scalars["ID"];
};

export type Query = {
  __typename?: "Query";
  getJobs: Array<Job>;
};

export type QueryGetJobsArgs = {
  filter?: Maybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  createJob: Job;
};

export type MutationCreateJobArgs = {
  id: Scalars["ID"];
  originalUrl: Scalars["String"];
  title: Scalars["String"];
  companyName: Scalars["String"];
  employmentType: Scalars["String"];
  createdAt: Scalars["String"];
  logoUrl?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  industry?: Maybe<Scalars["String"]>;
  coordinates?: Maybe<Scalars["String"]>;
  rawContent?: Maybe<Scalars["String"]>;
  isDeleted: Scalars["Boolean"];
};

export type Job = Node & {
  __typename?: "Job";
  id: Scalars["ID"];
  originalUrl: Scalars["String"];
  title: Scalars["String"];
  companyName: Scalars["String"];
  employmentType: Scalars["String"];
  createdAt: Scalars["String"];
  logoUrl?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  industry?: Maybe<Scalars["String"]>;
  coordinates?: Maybe<Scalars["String"]>;
  rawContent?: Maybe<Scalars["String"]>;
  isDeleted: Scalars["Boolean"];
};

export type Company = Node & {
  __typename?: "Company";
  id: Scalars["ID"];
  name: Scalars["String"];
  employees?: Maybe<Scalars["Int"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  industry?: Maybe<Scalars["String"]>;
  isDeleted: Scalars["Boolean"];
};

export type CreateJobMutationVariables = Exact<{
  id: Scalars["ID"];
  originalUrl: Scalars["String"];
  title: Scalars["String"];
  companyName: Scalars["String"];
  employmentType: Scalars["String"];
  createdAt: Scalars["String"];
  logoUrl?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  industry?: Maybe<Scalars["String"]>;
  coordinates?: Maybe<Scalars["String"]>;
  rawContent?: Maybe<Scalars["String"]>;
  isDeleted: Scalars["Boolean"];
}>;

export type CreateJobMutation = { __typename?: "Mutation" } & {
  createJob: { __typename?: "Job" } & Pick<Job, "id">;
};

export type GetJobsQueryVariables = Exact<{
  filter?: Maybe<Scalars["String"]>;
}>;

export type GetJobsQuery = { __typename?: "Query" } & {
  getJobs: Array<{ __typename?: "Job" } & JobFieldsFragment>;
};

export type JobFieldsFragment = { __typename?: "Job" } & Pick<
  Job,
  | "id"
  | "originalUrl"
  | "title"
  | "companyName"
  | "employmentType"
  | "createdAt"
  | "logoUrl"
  | "location"
  | "industry"
  | "coordinates"
  | "rawContent"
  | "isDeleted"
>;

export const JobFieldsFragmentDoc = gql`
  fragment JobFields on Job {
    id
    originalUrl
    title
    companyName
    employmentType
    createdAt
    logoUrl
    location
    industry
    coordinates
    rawContent
    isDeleted
  }
`;
export const CreateJobDocument = gql`
  mutation createJob(
    $id: ID!
    $originalUrl: String!
    $title: String!
    $companyName: String!
    $employmentType: String!
    $createdAt: String!
    $logoUrl: String
    $location: String
    $industry: String
    $coordinates: String
    $rawContent: String
    $isDeleted: Boolean!
  ) {
    createJob(
      id: $id
      originalUrl: $originalUrl
      title: $title
      companyName: $companyName
      employmentType: $employmentType
      createdAt: $createdAt
      logoUrl: $logoUrl
      location: $location
      industry: $industry
      coordinates: $coordinates
      rawContent: $rawContent
      isDeleted: $isDeleted
    ) {
      id
    }
  }
`;
export const GetJobsDocument = gql`
  query getJobs($filter: String) {
    getJobs(filter: $filter) {
      ...JobFields
    }
  }
  ${JobFieldsFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (sdkFunction) => sdkFunction();
export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    createJob(
      variables: CreateJobMutationVariables
    ): Promise<CreateJobMutation> {
      return withWrapper(() =>
        client.request<CreateJobMutation>(print(CreateJobDocument), variables)
      );
    },
    getJobs(variables?: GetJobsQueryVariables): Promise<GetJobsQuery> {
      return withWrapper(() =>
        client.request<GetJobsQuery>(print(GetJobsDocument), variables)
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
