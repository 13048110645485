import React from "react";
import { parseISO } from "date-fns";

import { Job } from "../models/job";

import jobGridStyle from "./jobgrid.module.scss";
import jobCardStyle from "./jobcard.module.scss";

const JobGridRender = (props: any) => {
  return (
    <div className={jobGridStyle.container}>
      {" "}
      {props.filteredJobList.map((job: Job) => (
        <JobCard
          key={job.id}
          title={job.title}
          companyName={job.companyName}
          companyLogo={job.logoUrl}
          region={job.location}
          date={job.createdAt}
          url={job.originalUrl}
        />
      ))}
    </div>
  );
};

const JobCard = (props: any) => {
  const daysAgo = _calculateDaysAgo(props.date);
  const footerText =
    daysAgo + (_calculateDaysAgo(props.date) > 1 ? " days ago" : " day ago");
  return (
    <div
      className={jobCardStyle.container}
      onClick={() => window.open(props.url, "_blank")}
    >
      <div className={jobCardStyle.logo}>
        <img
          width="64"
          title={props.companyName}
          src={props.companyLogo}
          height="64"
          alt={props.companyName}
        />
      </div>
      <div className={jobCardStyle.content}>
        <p>
          <b>{props.title}</b>
        </p>
        {props.companyName}
        <br />
        <span>
          <span role="img" aria-label="location">
            📍
          </span>{" "}
          {props.region}
        </span>
      </div>
      <div className={jobCardStyle.footer}>
        <span>{footerText}</span>
      </div>
    </div>
  );
};

const _calculateDaysAgo = (dateISOStr: string) => {
  const date = parseISO(dateISOStr);
  const today = new Date();
  const timeinmilisec = today.getTime() - date.getTime();
  return Math.floor(timeinmilisec / (1000 * 60 * 60 * 24));
};

export default JobGridRender;
