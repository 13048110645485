import React from "react";

// Project modules
import containerStyle from "./container.module.scss";
import footerStyle from "./footer.module.scss";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { date: new Date() };
  }

  render() {
    return (
      <div>
        <div className={footerStyle.separator} />
        <footer className={footerStyle.footer}>
          <div className={containerStyle.container}>
            <div className={footerStyle.container}>
              <span>
                Made with{" "}
                <span role="img" aria-label="love">
                  ❤️
                </span>{" "}
                for all developers in Switzerland.
              </span>
              <span>© {this.state.date.getFullYear()}</span>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
