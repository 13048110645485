// import npm modules
import React, { useState, useEffect } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { getDistance } from "geolib";

// import models
import { Job } from "../models/job";
import { JobRequest } from "../models/jobRequest";
import { Coordinates } from "../models/coordinates";

// import custom modules
import Loader from "../components/layout/loader/loader";
import Layout from "../components/layout/layout";
import SearchBox from "../components/searchbox";
import JobGridRender from "../components/jobgridrender";

// import helper functions
import { GeoLocationService } from "../helpers/geolocationService";
import useDebounce from "../helpers/useDebounce";

// import graphql
import { GetJobsDocument } from "../models/graphql/types";

const geoLocationService = new GeoLocationService();

const MainPage = () => {
  // ***********************
  // Fetch Jobdata from API
  // ***********************
  const [jobRequest, setJobRequest] = useState<JobRequest>({
    isLoaded: false,
    data: [],
  });

  const callAPI = async () => {
    try {
      const response = (await API.graphql(
        graphqlOperation(GetJobsDocument)
      )) as {
        data: {
          getJobs: Job[];
        };
      };
      const sortedJobsArray = response.data.getJobs
        .slice()
        .sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
        );
      setJobRequest({
        isLoaded: true,
        data: sortedJobsArray,
      });
    } catch (err) {
      throw err;
    }
  };

  // Make API Call
  useEffect(() => {
    callAPI();
  }, []);

  // ***********************
  // Handle changes in location
  // ***********************

  const [queryLocation, setQueryLocation] = useState<string>("");
  const debouncedQueryLocation = useDebounce(queryLocation, 500);
  const [queryLocationCoordinates, setQueryLocationCoordinates] = useState<
    Coordinates | undefined
  >();

  const updateQueryLocation = async () => {
    try {
      const response = await geoLocationService.fromAddress(
        queryLocation || ""
      );
      setQueryLocationCoordinates(response);
    } catch (err) {
      setQueryLocationCoordinates(undefined);
    }
  };

  useEffect(() => {
    if (debouncedQueryLocation) {
      updateQueryLocation();
    } else {
      setQueryLocationCoordinates(undefined);
    }
  }, [debouncedQueryLocation]);

  // ***********************
  // Filters Job Displays
  // ***********************

  const [querySearchTerm, setQuerySearchTerm] = useState<string>("");
  const [filteredJobList, setFilteredJobList] = useState<Job[]>([]);

  const titleFilter = (job: Job) => {
    // checks if the search term matches the metadata of the job
    let queryIsMatch = true;
    if (querySearchTerm) {
      const queryText = job.title + job.companyName + job.employmentType;
      queryIsMatch = queryText
        .toLowerCase()
        .includes(querySearchTerm.toLowerCase());
    }

    // checks if the job location is in range of the search location
    let locationInRange = true;
    if (queryLocationCoordinates) {
      if (job.coordinates) {
        const coordinatesSplit = job.coordinates.split(",");
        const lat = coordinatesSplit[0] || "";
        const long = coordinatesSplit[1] || "";
        const queryDistance = getDistance(
          { longitude: long, latitude: lat },
          queryLocationCoordinates
        ); //hardcoded, fix later --> also later if no location data saved then return location in range = true
        locationInRange = queryDistance <= 20000;
      } else {
        locationInRange = false;
      }
    }

    // returns true if the query is matched and the location is in range
    return queryIsMatch && locationInRange;
  };

  useEffect(() => {
    if (jobRequest.isLoaded) {
      setFilteredJobList(jobRequest.data.filter(titleFilter));
    }
  }, [jobRequest.isLoaded, querySearchTerm, queryLocationCoordinates]);

  return (
    <Layout>
      <SearchBox
        querySearchTerm={querySearchTerm}
        setQuerySearchTerm={setQuerySearchTerm}
        queryLocation={queryLocation}
        setQueryLocation={setQueryLocation}
      />
      {jobRequest.isLoaded ? (
        <JobGridRender filteredJobList={filteredJobList} />
      ) : (
        <Loader />
      )}
    </Layout>
  );
};

export default MainPage;
