import React from "react";
import navbarStyle from "./navbar.module.scss";
import containerStyle from "./container.module.scss";

class Navbar extends React.Component {
  render() {
    return (
      <div className={navbarStyle.navbar}>
        <div className={containerStyle.container}>
          <div className={navbarStyle.container}>
            <a href="">
              <img
                className={navbarStyle.img}
                src={process.env.PUBLIC_URL + "/logo.svg"}
                alt="Logo"
              />
            </a>
            <a style={{ alignSelf: "center" }} href="">
              <span role="img" aria-label="sheep">
                ✉️
              </span>{" "}
              Feedback
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
