import React from "react";
import MainPage from "./screens/Main";
import Amplify from "@aws-amplify/core";
import awsmobile from "./aws-exports";
Amplify.configure(awsmobile);

function App() {
  return <MainPage />;
}

export default App;
