import Geocode from "react-geocode";

export class GeoLocationService {
  constructor() {
    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey("AIzaSyCVsxATuao6_6frjpsySFxOnl__ioi8Phc");

    // set response language. Defaults to english.
    Geocode.setLanguage("de");

    // set response region. Its optional.
    // A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion("ch");

    // Enable or disable logs. Its optional.
    // Geocode.enableDebug();
  }

  // Get address from latitude & longitude.
  fromLatLng = async (latitude: string, longitude: string): Promise<string> => {
    try {
      const response = await Geocode.fromLatLng(latitude, longitude);
      const address = response.results[0].formatted_address;
      return address;
    } catch (error) {
      throw error;
    }
  };

  // Get latitude & longitude from address.
  fromAddress = async (
    address: string
  ): Promise<{ latitude: string; longitude: string }> => {
    try {
      const response = await Geocode.fromAddress(address);
      const { lat, lng } = response.results[0].geometry.location;
      return { latitude: lat, longitude: lng };
    } catch (error) {
      throw error;
    }
  };
}
