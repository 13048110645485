// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_appsync_graphqlEndpoint:
    "https://tk3jtnxfrbbfpb2iasoe2hnoby.appsync-api.eu-central-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-xafwy6uxtrdanpylbwku5wzyom",
};

export default awsmobile;
